<template>
  <div class="mt-5">
    <b-tabs pills vertical v-model="currentTab">
      <b-tab title="In" lazy>
        <SchoolFacialStaffTable :device="device"></SchoolFacialStaffTable>
      </b-tab>
      <b-tab :title="$t('DEVICEEMPLOYEE.LIST')" lazy>
        <SchoolFacialStaffAvailableTable
          :device="device"
        ></SchoolFacialStaffAvailableTable
      ></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SchoolFacialStaffTable from "@/modules/school/components/device/facial/user/staff/SchoolFacialStaffTable";
import SchoolFacialStaffAvailableTable from "@/modules/school/components/device/facial/user/staff/SchoolFacialStaffAvailableTable";
export default {
  name: "SchoolFacialDeviceStaffModal",
  components: { SchoolFacialStaffAvailableTable, SchoolFacialStaffTable },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTab: 0,
    };
  },
};
</script>

<style scoped></style>
