<template>
  <CommonFacialDeviceModal :title="selectedDevice.name">
    <b-overlay :show="busy" spinner-variant="primary">
      <div class="d-flex mb-5 flex-wrap">
        <div class="symbol symbol-60 symbol-light mr-5">
          <span class="symbol-label svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg
              class="svg-icon-5x"
              src="/media/svg/icons/Devices/Tablet.svg"
            />
          </span>
        </div>
        <div class="d-flex flex-column p-2">
          <span class="font-weight-bolder">{{ selectedDevice.code }}</span>
          <span>{{ selectedDevice.model }}</span>
          <span class="text-muted"> Active </span>
        </div>
        <div class="ml-auto align-self-center">
          <b-button
            variant="primary"
            class="font-weight-bolder"
            v-on:click="synchronize"
            ><span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Devices/Generator.svg" />
            </span>
            {{ $t("DEVICEEMPLOYEE.SYNC") }}
          </b-button>
        </div>
      </div>
      <div class="mb-5">
        <b-tabs pills horizontal v-model="currentTab">
          <b-tab title="Teacher" lazy>
            <SchoolFacialDeviceTeacherModal
              :device="selectedDevice"
            ></SchoolFacialDeviceTeacherModal>
          </b-tab>
          <b-tab title="Staff" lazy>
            <SchoolFacialDeviceStaffModal
              :device="selectedDevice"
            ></SchoolFacialDeviceStaffModal>
          </b-tab>
        </b-tabs>
      </div>
    </b-overlay>
  </CommonFacialDeviceModal>
</template>

<script>
import CommonFacialDeviceModal from "@/modules/school/components/device/facial/user/common/CommonFacialDeviceModal";
import SchoolFacialDeviceTeacherModal from "@/modules/school/components/device/facial/user/teacher/SchoolFacialDeviceTeacherModal";
import SchoolFacialDeviceStaffModal from "@/modules/school/components/device/facial/user/staff/SchoolFacialDeviceStaffModal";
export default {
  name: "SchoolFacialUserModal",
  components: {
    SchoolFacialDeviceStaffModal,
    SchoolFacialDeviceTeacherModal,
    CommonFacialDeviceModal,
  },
  props: {
    selectedDevice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentTab: 0,
      busy: false,
    };
  },

  methods: {
    synchronize() {
      this.busy = true;
      this.$store
        .dispatch("syncSchoolDevice", {
          id: this.selectedDevice.id,
        })
        .then(() => {
          this.$bvToast.toast("Successfully synchronized device.", {
            title: "Success",
            solid: true,
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to synchronized device.", {
            title: "Failed",
            variant: "danger",
            headerClass: "h4",
            autoHideDelay: 5000,
          });
        })
        .finally(() => (this.busy = false));
    },
  },
};
</script>

<style scoped></style>
