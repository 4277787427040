<template>
  <div>
    <b-table
      id="facial-device-table"
      ref="facial-device-table"
      responsive="sm"
      :items="items"
      :fields="fields"
      show-empty
      :busy="isBusy"
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex pl-3 align-items-center">
          <div class="symbol symbol-40 symbol-light mr-5">
            <span class="symbol-label svg-icon svg-icon-primary">
              <inline-svg
                class="h-100 align-self-end"
                src="/media/svg/icons/Devices/Tablet.svg"
              />
            </span>
          </div>
          <span class="text-dark-75 font-weight-bolder font-size-lg">{{
            data.item.name
          }}</span>
        </div>
      </template>

      <template v-slot:cell(model)="data">
        <div class="d-flex flex-column mt-1 mb-2">
          <span class="text-dark-75 font-weight-bolder font-size-lg">
            {{ data.item.model }}
          </span>

          <span>{{ data.item.make }}</span>
        </div>
      </template>

      <!-- Actions   -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showDeviceUserForm(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </button>
          <!-- end: Device Users Modal Button         -->

          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showDeviceEditForm(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>

          <button
            class="btn btn-icon btn-light btn-hover-danger mr-3"
            v-on:click="deleteFacialDevice(data.item)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-devices button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No facial device registered"
          text-bottom="To register facial device, click at the CREATE button"
        ></AppEmptyList>
      </template>
    </b-table>

    <SchoolFacialUserModal
      :selected-device="selectedDevice"
    ></SchoolFacialUserModal>

    <SchoolFacialEditModal
      :selected-device="selectedDevice"
      @detailsUpdated="refresh"
      :title="selectedDevice.name"
    ></SchoolFacialEditModal>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="facial-device-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import SchoolFacialEditModal from "@/modules/school/components/device/facial/SchoolFacialEditModal";
import SchoolFacialUserModal from "@/modules/school/components/device/facial/user/SchoolFacialUserModal";

export default {
  name: "SchoolFacialDevicePageTable",
  components: {
    SchoolFacialUserModal,
    SchoolFacialEditModal,
    AppLoader,
    AppEmptyList,
  },
  props: ["reload"],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: "index", label: "#" },
        {
          key: "name",
          label: this.$t("DEVICE.FACIAL.PAGE.DEVICE"),
          sortable: true,
        },
        { key: "name", label: this.$t("DEVICE.FACIAL.PAGE.MODEL") },
        { key: "code", label: this.$t("DEVICE.FACIAL.PAGE.SERIALNUMBER") },
        { key: "status", label: "Status" },
        { key: "actions", label: this.$t("DEVICE.FACIAL.PAGE.ACTIONS") },
      ],
      selectedDevice: {},
      isBusy: false,
    };
  },

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      this.isBusy = true;

      try {
        const res = await this.$store.dispatch("fetchFDList");
        console.log("Received", res);

        return res.data.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.isBusy = false;
      }
    },

    refresh() {
      console.log("Reload Table!");
      this.fetch();
    },

    /**
     * Change the page to the given value
     *
     * @param page
     */
    changePage(page) {
      console.log("Change page to ", page);
      this.$store.commit("setFDListPage", page);
      this.fetch();
    },

    /**
     * Delete Selected Facial Device
     *
     * @param row
     */
    deleteFacialDevice(row) {
      console.log("Selected: ", row.name);

      this.$bvModal
        .msgBoxConfirm(`This will delete ${row.name}`, {
          title: this.$t("ALERT.SURE"),
          centered: true,
          noCloseOnBackdrop: true,
          cancelVariant: "light",
        })
        .then((value) => {
          console.log("Hmmm", value);
          if (value) {
            return new Promise((resolve, reject) => {
              this.$store
                .dispatch("deleteSchoolFacialDevice", row.id)
                .then(() => {
                  this.$bvModal.msgBoxOk("Success!!", {
                    centered: true,
                  });
                  this.refresh();
                  resolve(value);
                })
                .catch(() => {
                  console.error("Some issues bro");
                  this.$bvModal.msgBoxOk("Failed!!", {
                    centered: true,
                    okVariant: "danger",
                  });
                  reject(value);
                });
            });
          }
        })
        .catch((err) => {
          console.error("Some issues bro", err);
        });
    },

    showDeviceEditForm(row) {
      console.log("Selected device ", row);

      this.selectedDevice = row.item;

      this.$bvModal.show("common-employee-details-modal");
    },

    showDeviceUserForm(row) {
      console.log("Selected device ", row);

      this.selectedDevice = row.item;

      this.$bvModal.show("common-facial-device-modal");
    },
  },

  watch: {
    reload() {
      this.fetch();
    },
  },

  computed: {
    ...mapGetters({
      items: "getFDList",
      pagination: "getFDListPagination",
      currentPage: "getFDListPage",
    }),
  },
};
</script>

<style scoped></style>
