<template>
  <b-modal
    id="common-employee-details-modal"
    title="Edit Device Detail"
    hide-footer
  >
    <CommonFacialDeviceDetailForm
      :is-busy="isBusy"
      @submit="handleSubmit"
      @check-code="checkCode"
      :form-set="selectedDevice"
      :code-exist-status="codeStatus"
    ></CommonFacialDeviceDetailForm>
  </b-modal>
</template>

<script>
import CommonFacialDeviceDetailForm from "@/modules/school/components/shared/device/form/CommonFacialDeviceDetailForm";

export default {
  name: "SchoolFacialEditModal",
  components: { CommonFacialDeviceDetailForm },
  props: {
    title: {
      String,
    },
    selectedDevice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      codeStatus: false,
    };
  },

  mounted() {
    console.log("Mounted", this.selectedDevice);
  },

  methods: {
    handleSubmit(form) {
      console.log(form);

      const payload = {
        ...form,
        id: this.selectedDevice.id,
      };

      this.isBusy = true;
      this.$store
        .dispatch("updateFacialDeviceDetails", payload)
        .then((res) => {
          console.log(res);
          this.$bvModal.msgBoxOk("test success");
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("detailsUpdated");
        });
    },

    checkCode(code) {
      console.log("Watching: ", code);

      if (code) {
        const payload = {
          code: code.code,
          id: code.id,
        };

        console.log("Kat Sini");
        this.$store
          .dispatch("checkExistingFacialDeviceSerialNumber", payload)
          .then((response) => {
            console.log(response);
            this.codeStatus = response.data.status === "true";
            if (response.data.status === "true") {
              console.log("Data Exist");
            }
            console.log("New Code Status: ", this.codeStatus);
          });
      }
    },
  },
};
</script>

<style scoped></style>
