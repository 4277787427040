<template>
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center"
      responsive="sm"
      show-empty
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      @row-clicked="handleRowClicked"
    >
      <!-- begin: When table's empty -->
      <template #empty="">
        <AppEmptyList
          :text-top="caption.main"
          :text-bottom="caption.sub"
        ></AppEmptyList>
      </template>
      <!-- end: When table's empty -->

      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>
      <template v-slot:cell(selected)="data">
        <div class="d-flex mt-1 mb-2 justify-content-center">
          <b-form-checkbox
            size="lg"
            class="h6"
            v-model="data.item.selected"
            @change="onChecked(data.item, $event)"
          ></b-form-checkbox>
        </div>
      </template>
      <template v-slot:cell(name)="data">
        <CommonEmployeeSummary :item="data.item"></CommonEmployeeSummary>
      </template>

      <template #table-busy>
        <AppLoader />
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        align="right"
        class="pt-5"
        @change="$emit('changePage', $event)"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="employee-page-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "CommonFacialDeviceUserTable",
  components: { AppLoader, AppEmptyList, CommonEmployeeSummary },
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    caption: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "selected", label: "Select", class: "text-center" },
        { key: "name", label: this.$t("EMPLOYEESGENERAL.MESSAGE.FIRSTNAME") },
      ],

      selected: {},
    };
  },
  methods: {
    onChecked(data, state) {
      console.log("Test on check", state);
      this.$emit("onChecked", data, state);
    },

    /**
     * handle row click
     * @param row
     */
    handleRowClicked(row) {
      console.log("Selected Row: ", row);
      const index = this.items.findIndex((e) => e.id === row.id);
      this.items[index].selected = !this.items[index].selected;

      this.$emit("onChecked", this.items[index], this.items[index].selected);
    },
  },
};
</script>

<style scoped></style>
