<template>
  <AppTableControl
    no-export
    @query-debounced="searchByName"
    @search-clicked="$store.dispatch('fetchFDList')"
    @reset-filters="resetFilters"
  >
    <template #filters>
      <div class="d-flex">
        <!-- begin: Purchase Date     -->
        <b-form-group
          class="mr-4"
          label-class="text-bolder"
          label="Purchase Date"
        >
          <b-form-datepicker
            v-model="purchaseDate"
            id="from-date"
            class="form-control h-auto"
            @input="inputPurchaseDate"
          >
          </b-form-datepicker>
        </b-form-group>
        <!-- end: Purchase Date     -->
      </div>
    </template>
  </AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
import dayjs from "dayjs";

export default {
  name: "SchoolFacialDeviceTableControl",
  components: { AppTableControl },
  data() {
    return {
      purchaseDate: null,
    };
  },
  beforeDestroy() {
    this.resetFilters();
  },
  methods: {
    /**
     * Name query search.
     *
     * @param name
     */
    searchByName(name) {
      console.log(name);

      this.$store.dispatch("changeFDListNameFilter", { name: name });
      this.$store.dispatch("fetchFDList");
    },

    /**
     * Reset the filters
     */
    resetFilters() {
      this.purchaseDate = null;

      this.inputPurchaseDate(null);
      this.$store.dispatch("fetchFDList");
    },

    /**
     * Watch purchase date input
     *
     * @param date
     */
    inputPurchaseDate(date) {
      let formattedDate = date ? dayjs(date).format() : null;
      console.log(formattedDate);

      this.$store.dispatch("changeFDListPurchaseDateFilter", {
        date: formattedDate,
      });
    },
  },
};
</script>

<style scoped></style>
