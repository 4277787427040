<template>
  <div>
    <SchoolFacialDeviceTableControl
      class="mb-5"
    ></SchoolFacialDeviceTableControl>

    <AppPageCard>
      <template #card-title> Facial Devices </template>
      <template #card-toolbar>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <b-button
          variant="light-primary"
          class="font-weight-bolder"
          v-b-toggle.facial-device-list-sidebar
        >
          {{ $t("TEACHER.BUTTON") }}
        </b-button>
      </template>

      <SchoolFacialDevicePageTable
        :reload="reload"
      ></SchoolFacialDevicePageTable>

      <b-sidebar
        ref="facial-device-list-sidebar-ref"
        id="facial-device-list-sidebar"
        backdrop
        right
        lazy
        :title="$t('USERFORM.CREATE')"
        sidebar-class="offcanvas"
        header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
        bg-variant="white"
      >
        <template v-slot:header-close>
          <button
            class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
          >
            <i class="ki ki-close icon-xs"></i>
          </button>
        </template>
        <div class="px-10 mt-5">
          <SchoolFacialPageCreateForm
            title="Device Details"
            v-on:success="closeSideBar"
          ></SchoolFacialPageCreateForm>
        </div>
      </b-sidebar>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolFacialDevicePageTable from "@/modules/school/components/device/facial/SchoolFacialDevicePageTable";
import SchoolFacialPageCreateForm from "@/modules/school/components/device/facial/SchoolFacialPageCreateForm";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SchoolFacialDeviceTableControl from "@/modules/school/components/device/facial/control/SchoolFacialDeviceTableControl";
export default {
  name: "SchoolFacialDevicePage",
  components: {
    SchoolFacialDeviceTableControl,
    SchoolFacialPageCreateForm,
    SchoolFacialDevicePageTable,
    AppPageCard,
  },
  data() {
    return {
      reload: false,
    };
  },
  mounted() {
    console.log("Device List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      {
        title: this.$t("SIDEBAR.EMPLOYEE"),
        route: "/school/device/facial/list",
      },
    ]);
  },
  methods: {
    closeSideBar() {
      const sideBar = this.$refs["facial-device-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
