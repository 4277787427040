<template>
  <b-card class="card-custom" body-class="p-3">
    <!-- begin:: Main Flexbox  -->
    <div class="mx-3 d-flex justify-content-between">
      <div class="flex-grow-1 mr-3 mr-lg-6">
        <b-form-input
          v-model="query"
          :placeholder="searchPlaceHolder"
          :debounce="searchDelay"
        ></b-form-input>
      </div>

      <!-- begin::: buttons  -->
      <div>
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          @click="$emit('search-clicked')"
          v-b-popover.hover.bottom="$t('COMMON.TOOLTIP.Search')"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Search.svg" />
          </span>
        </b-button>

        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-b-toggle.collapse-1
          :pressed="isCollapsed"
          v-if="!noFilter"
          v-b-popover.hover.bottom="$t('COMMON.TOOLTIP.Search')"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Text/Filter.svg" />
          </span>
        </b-button>

        <b-dropdown
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          no-caret
          variant="link"
          right
          v-b-popover.hover.bottom="$t('COMMON.TOOLTIP.Config')"
        >
          <template #button-content>
            <span class="svg-icon svg-icon-2x svg-icon-primary">
              <inline-svg src="/media/svg/icons/Shopping/Settings.svg" />
            </span>
          </template>

          <b-dropdown-header>
            {{ $t("COMMON.Table.Actions.Base") }}
          </b-dropdown-header>

          <b-dropdown-item v-if="!noFilter" @click="$emit('reset-filters')">
            <span class="symbol symbol-50 mr-6">
              <inline-svg src="/media/svg/icons/Shopping/Settings.svg" />
            </span>
            {{ $t("COMMON.Table.Actions.Reset_Filters") }}
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-header v-if="!noExport"> Files</b-dropdown-header>

          <b-dropdown-item
            @click="$emit('export-excel-clicked')"
            v-if="!noExport"
          >
            <span class="symbol symbol-50 mr-6">
              <inline-svg
                src="/media/svg/icons/Communication/Clipboard-list.svg"
              />
            </span>
            {{ $t("COMMON.Table.Actions.Export_Excel") }}
          </b-dropdown-item>

          <b-dropdown-item
            @click="$emit('export-pdf-clicked')"
            v-if="!noExport"
          >
            <span class="symbol symbol-50 mr-6">
              <inline-svg src="/media/svg/icons/Files/Export.svg" />
            </span>
            Export - PDF
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- end::: buttons  -->
    </div>
    <!-- end:: Main Flexbox  -->

    <b-collapse
      id="collapse-1"
      class="px-5 pt-4"
      v-model="isCollapsed"
      v-if="!noFilter"
    >
      <slot name="filters"></slot>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: "AppTableControl",
  props: {
    searchDelay: {
      type: Number,
      default: 500,
    },

    noExport: {
      type: Boolean,
      default: false,
    },

    noFilter: {
      type: Boolean,
      default: false,
    },

    searchPlaceHolder: {
      type: String,
      default: "Search",
    },
  },

  data() {
    return {
      isCollapsed: false,
      query: "",
    };
  },

  watch: {
    /**
     * Watch query debounce
     *
     * @param newQuery
     */
    query(newQuery) {
      this.$emit("query-debounced", newQuery);
    },
  },
};
</script>

<style scoped></style>
