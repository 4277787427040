<template>
  <div>
    <div class="d-flex mb-5 flex-wrap">
      <div class="ml-auto">
        <b-button
          class="font-weight-bolder btn-hover-primary-dark"
          variant="primary"
          v-on:click="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/General/Save.svg" /> </span
          >{{ $t("DEVICEEMPLOYEE.SAVE") }}</b-button
        >
      </div>
    </div>
    <CommonFacialDeviceUserTable
      :items="items"
      :pagination="pagination"
      :is-busy="isBusy"
      :caption="caption"
      @changePage="handlePageChange"
      @onChecked="handleCheckbox"
    ></CommonFacialDeviceUserTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonFacialDeviceUserTable from "@/modules/school/components/device/facial/user/common/CommonFacialDeviceUserTable";

export default {
  name: "SchoolFacialStaffAvailableTable",
  components: { CommonFacialDeviceUserTable },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      received: [],
      staff: [],
      caption: {
        main: "All staff added",
        sub: "To view staff in this device, go to In",
      },
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      const payload = {
        id: this.device.id,
      };
      this.isBusy = true;
      this.$store
        .dispatch("fetchFDStaffListNotIn", payload)
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setFDStaffListPage", page);
      this.fetch();
    },

    /**
     * Call store to change selected value for item
     */
    handleCheckbox(data, state) {
      console.log("Emitting from checkbox", data.id, state);
      this.$store.dispatch("changeStaffSelectedStatus", {
        id: data.id,
        currentState: state,
      });
    },

    submit() {
      this.items.forEach((e) => {
        if (e.selected) {
          this.staff.push(e.id);
        }
      });

      console.log("Final Staff Id: ", this.staff);

      this.isBusy = true;
      this.$store
        .dispatch("addStaffInFacialDevice", {
          fd_id: this.device.id,
          school_employee: this.staff,
        })
        .then(() => {
          this.$bvToast.toast("Successfully add staff to device.", {
            title: "Success",
            solid: true,
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to add staff to device.", {
            title: "Failed",
            variant: "danger",
            headerClass: "h4",
            autoHideDelay: 5000,
          });
        })
        .finally(() => {
          this.staff = [];
          this.isBusy = false;
          this.fetch();
        });
    },
  },

  computed: {
    ...mapGetters({
      items: "getFDStaffList",
      pagination: "getFDStaffListPagination",
    }),
  },
};
</script>

<style scoped></style>
