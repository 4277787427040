<template>
  <b-modal
    id="common-facial-device-modal"
    :title="title"
    title-class="h4 font-weight-bold"
    lazy
    size="xl"
    hide-footer
  >
    <slot> </slot>
  </b-modal>
</template>

<script>
export default {
  name: "CommonFacialDeviceModal",
  props: {
    title: {
      String,
    },
  },
};
</script>

<style scoped></style>
