<template>
  <div>
    <div class="d-flex mb-5 flex-wrap">
      <div class="ml-auto">
        <b-button
          class="font-weight-bolder btn-icon mr-2"
          variant="light-primary"
          v-on:click="deleteStaff"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/General/Trash.svg" /> </span
        ></b-button>
        >
      </div>
    </div>
    <CommonFacialDeviceUserTable
      :items="items"
      :pagination="pagination"
      :is-busy="isBusy"
      @changePage="handlePageChange"
      :caption="caption"
      @onChecked="handleCheckbox"
    ></CommonFacialDeviceUserTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonFacialDeviceUserTable from "@/modules/school/components/device/facial/user/common/CommonFacialDeviceUserTable";

export default {
  name: "SchoolFacialStaffTable",
  components: { CommonFacialDeviceUserTable },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      staff: [],
      caption: {
        main: "No Staff Added",
        sub: "To add staff to this device, go to List",
      },
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      const payload = {
        id: this.device.id,
      };
      this.isBusy = true;
      this.$store
        .dispatch("fetchFDStaffListIn", payload)
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    handlePageChange(page) {
      console.log("Current Page is", page);
      this.$store.commit("setFDStaffListPage", page);
      this.fetch();
    },

    /**
     * Call store to change selected value for item
     */
    handleCheckbox(data, state) {
      console.log("Emitting from checkbox", data.id, state);
      this.$store.dispatch("changeStaffSelectedStatus", {
        id: data.id,
        currentState: state,
      });
    },

    deleteStaff() {
      this.items.forEach((e) => {
        if (e.selected) {
          this.staff.push(e.id);
        }
      });

      this.isBusy = true;
      this.$store
        .dispatch("deleteStaffFacialDevice", {
          fd_id: this.device.id,
          school_employee: this.staff,
        })
        .then(() => {
          this.$bvToast.toast("Successfully delete staff to device.", {
            title: "Success",
            solid: true,
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Failed to delete teacher to device.", {
            title: "Failed",
            variant: "danger",
            headerClass: "h4",
            autoHideDelay: 5000,
          });
        })
        .finally(() => {
          this.staff = [];
          this.isBusy = false;
          this.fetch();
        });
    },
  },

  computed: {
    ...mapGetters({
      items: "getFDStaffList",
      pagination: "getFDStaffListPagination",
    }),
  },
};
</script>

<style scoped></style>
