<template>
  <b-form class="form" v-on:submit.prevent="save">
    <!-- begin: Facial Device Form    -->
    <b-form-group
      label-cols-lg="3"
      :label="$t('DEPARTMENTFORM.NAME')"
      label-for="facial-device-form"
    >
      <b-form-input
        id="facial-device-form"
        :placeholder="$t('DEPARTMENTFORM.NAME1')"
        v-model="$v.form.name.$model"
        :state="validateState('name')"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.name.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-cols-lg="3"
      :label="$t('DEVICEFORM.SERIALNUM')"
      label-for="facial-device-name-input"
    >
      <b-form-input
        id="facial-device-name-input"
        :placeholder="$t('DEVICEFORM.SERIALNUM1')"
        v-model="$v.form.code.$model"
        :state="validateState('code')"
        debounce="500"
      >
      </b-form-input>

      <b-form-invalid-feedback v-if="!$v.form.code.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>

      <b-form-invalid-feedback v-if="!$v.form.code.$params.codeStatus">
        Code Status cannot be used. In use by other organisation
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-cols-lg="3"
      label-for="description"
      :label="$t('DEPARTMENTFORM.DESCRIPTION')"
    >
      <b-form-textarea
        id="description"
        v-model="$v.form.description.$model"
        :placeholder="$t('DEPARTMENTFORM.DESCRIPTION1')"
        :state="validateState('description')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.description.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label-for="device-date-input"
      label-cols-lg="3"
      :label="$t('DEVICEFORM.DATEPURCHASED')"
    >
      <b-form-datepicker
        id="device-date-input"
        class="form-control h-auto"
        v-model="$v.form.date.$model"
        :state="validateState('date')"
      >
      </b-form-datepicker>
    </b-form-group>
    <div class="d-flex justify-content-end border-top pt-3">
      <b-button
        ref="employee-general-button"
        variant="primary"
        type="submit"
        v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  name: "CommonFacialDeviceDetailForm",
  mixins: [validationMixin],
  props: {
    formSet: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
    codeExistStatus: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  mounted() {
    console.log("Form data set", this.formSet);
  },

  computed: {
    checkExistCode() {
      console.log("Code exist is", this.codeExistStatus);
      return this.codeExistStatus;
    },
  },

  data() {
    return {
      form: {
        name: this.formSet.name,
        description: this.formSet.description,
        code: this.formSet.code,
        date: this.formSet.date,
        code_confirm: this.codeExistStatus,
      },
      codeStatus: this.codeExistStatus,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      code: {
        required,
        minLength: minLength(6),
        codeExists: (value, vm) => {
          console.log(value, vm);
          console.log("Code Status", vm);
          return !vm.code_confirm;
        },
      },
      description: {
        required,
        minLength: minLength(3),
      },
      date: {
        required,
      },
      code_confirm: {
        required,
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    save() {
      console.log("Emit Common Details to submit event");
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        console.log("Form in complete");
        return;
      }

      this.$emit("submit", {
        name: this.form.name,
        description: this.form.description,
        serial_number: this.form.code,
        from_date: this.form.date,
      });
    },
  },

  watch: {
    "form.code": {
      handler(code) {
        this.$emit("check-code", {
          code: code,
          id: this.formSet.id,
        });
        console.log("Finish Emitting", code);
      },
      deep: true,
    },
    codeExistStatus: {
      handler(exist) {
        console.log("Code Exist changing..", exist);
        this.form.code_confirm = exist;
      },
    },
  },
};
</script>

<style scoped></style>
